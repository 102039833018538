import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { BlogSubscribeBanner } from 'modules/blog-subscribe-banner';
import { LatestArticles } from 'modules/latest-articles';
import { array } from 'prop-types';

import { Section } from './components/section';
import { Tabs } from './components/tabs';
import { SECTIONS } from './constants';

const SContainer = styled.div`
    margin-top: 5rem;

    ${CONSTANTS.MEDIA.max768`
        margin-top: 0rem;
    `}
`;

export const NewsView = ({ blogPosts }) => {
    const getSectionPosts = (searchedTags) => {
        const filteredGenre = blogPosts.filter(({ node }) => {
            const { tags } = node.metadata;

            const tagNames = new Set(tags.map((tag) => tag.name));
            return searchedTags.some((tag) => tagNames.has(tag));
        });

        return filteredGenre.slice(0, 3);
    };

    return (
        <SContainer>
            <LatestArticles latestPostsList={blogPosts.slice(0, 3)} />
            <Tabs />
            {SECTIONS.map(({ tags, ...restProps }) => (
                <Section
                    key={restProps.id}
                    posts={getSectionPosts(tags)}
                    {...restProps}
                />
            ))}

            <Container>
                <BlogSubscribeBanner />
            </Container>
        </SContainer>
    );
};

NewsView.propTypes = {
    blogPosts: array.isRequired,
};
