import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { BlogPostLink } from 'components/blog-post-link';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { array, bool } from 'prop-types';

const SContainer = styled(Container)`
    background-color: var(--gray-color-90);
    padding-top: 7rem;
    padding-bottom: var(--section-clamp);
    margin-bottom: 0;
`;

const SBackground = styled.div`
    ${({ fillBackground }) =>
        fillBackground &&
        css`
            background-color: var(--gray-color-90);
            width: 100%;
            height: 100%;
        `}
`;

const SWrapper = styled.div`
    display: flex;
    gap: 3.75rem;
    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
    `}
`;

const SMainPost = styled.div`
    flex: 0.67;
`;

const SAdditionalPosts = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.33;
    gap: 3.75rem;
    ${CONSTANTS.MEDIA.max768`
        gap: 1.5rem;
        flex-direction: row;
    `}
    ${CONSTANTS.MEDIA.max600`
        flex-direction: column;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    font-size: 1.375rem;
    margin-bottom: 1rem;
`;

const SDescription = styled.p`
    margin-bottom: 1.5rem;
    color: var(--gray-color-80);
`;

const Props = {
    latestPostsList: array.isRequired,
    fillBackground: bool,
};

export const LatestArticles = ({ latestPostsList, fillBackground }) => {
    const blogPostsList = latestPostsList?.map(({ node }, index) => {
        const isFirstIndex = index === 0;
        return (
            <BlogPostLink
                key={node.id}
                blogPost={node}
                withDescription={isFirstIndex}
                isSmallerTitle={!isFirstIndex}
                isMain={isFirstIndex}
                withTags
            />
        );
    });

    const title = 'view-news.latestArticles';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SBackground {...{ fillBackground }}>
            <SContainer>
                <SHeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SDescription>
                    <FormattedMessage id="view-news.description" />
                </SDescription>
                <SWrapper>
                    <SMainPost>{blogPostsList.slice(0, 1)}</SMainPost>
                    <SAdditionalPosts>
                        {blogPostsList.slice(1, 3)}
                    </SAdditionalPosts>
                </SWrapper>
            </SContainer>
        </SBackground>
    );
};

LatestArticles.propTypes = Props;
