import { useCallback, useEffect, useState } from 'react';

export const useSectionsNavigation = (data) => {
    const [currentSectionIndex, setCurrentTopicIndex] = useState(0);
    const [isListenerActive, setIsListenerActive] = useState(true);

    const handleScroll = useCallback(() => {
        const visibleSections = data.filter((id) => {
            const element = document.querySelector(`#${id}`);
            if (!element) {
                return false;
            }
            const rect = element.getBoundingClientRect();
            return rect.top <= window.innerHeight && rect.bottom >= 0;
        });

        if (visibleSections.length > 0) {
            setCurrentTopicIndex(
                data.indexOf(visibleSections[visibleSections.length - 1]),
            );
        }
    }, [data]);

    useEffect(() => {
        if (isListenerActive) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isListenerActive, handleScroll]);

    const scrollToHandler = (offset, callback) => {
        const fixedOffset = offset.toFixed(0);
        const onScroll = () => {
            if (
                window.pageYOffset.toFixed(0) === fixedOffset ||
                Number(window.pageYOffset.toFixed(0)) ===
                    Number(fixedOffset) + 1
            ) {
                window.removeEventListener('scroll', onScroll);
                callback();
            }
        };

        window.addEventListener('scroll', onScroll);
        onScroll();
        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    };

    const navigateToSection = (e, index) => {
        e.preventDefault();
        if (index === currentSectionIndex) {
            return;
        }

        setIsListenerActive(false);
        setCurrentTopicIndex(index);

        const rect = document
            .querySelector(`#${data[index]}`)
            .getBoundingClientRect();
        const offset = 200;

        scrollToHandler(rect.top + window.pageYOffset - offset, () => {
            setIsListenerActive(true);
        });
    };

    return { currentSectionIndex, navigateToSection };
};
