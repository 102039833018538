import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { useSectionsNavigation } from 'modules/technologies/sections-navigation-bar/hooks';
import { arrayOf, string } from 'prop-types';

const STopicsWrapper = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 100px;
    top: 6rem;
    display: flex;
    width: 100%;
    overflow-x: auto;
    position: sticky;
    padding: 0 var(--section-clamp);
    background-color: var(--white-color);
    z-index: ${CONSTANTS.ZINDEX.HIGHEST};

    ${CONSTANTS.MEDIA.max1024`
        top: 5rem;
    `};

    ${CONSTANTS.MEDIA.max420`
        padding: 0 1rem;
        gap: 1.75rem;
    `};
`;

const STopic = styled.button`
    min-width: 9.375rem;
    padding: 0 2rem 1rem;
    align-self: flex-end;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border-bottom: ${({ isSelected }) => {
        const appliedStyle = (color) => `2px solid var(${color})`;
        return isSelected
            ? appliedStyle('--primary-color')
            : appliedStyle('--gray-color-70');
    }};
    font-weight: ${({ isSelected }) => (isSelected ? 800 : 400)};
    cursor: ${({ isSelected }) => (isSelected ? 'auto' : 'pointer')};
`;

export const SectionsNavigationBar = ({ data, ids, className }) => {
    const { formatMessage } = useIntl();
    const { currentSectionIndex, navigateToSection } =
        useSectionsNavigation(ids);

    return (
        <STopicsWrapper className={className}>
            {data.map((topic, index) => (
                <STopic
                    key={index}
                    isSelected={index === currentSectionIndex}
                    aria-label={formatMessage({ id: topic })}
                    onClick={(e) => navigateToSection(e, index)}
                >
                    <FormattedMessage id={topic} />
                </STopic>
            ))}
        </STopicsWrapper>
    );
};

SectionsNavigationBar.propTypes = {
    topics: arrayOf(string).isRequired,
    ids: arrayOf(string).isRequired,
    className: string,
};
