import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { SectionsNavigationBar } from 'modules/technologies/sections-navigation-bar';

import { SECTIONS_ID, SECTIONS_TRANSLATION } from '../../constants';

const STabsWrapper = styled(Container)`
    padding: 0;
    background-color: var(--gray-color-90);
    margin-bottom: 0;
    position: sticky;
    top: 6rem;
    z-index: 5;

    ${CONSTANTS.MEDIA.max768`
        top: 5rem;
    `}
`;

const SNavigation = styled(SectionsNavigationBar)`
    padding-top: 1rem;
    padding-bottom: 1.5rem;

    && {
        display: grid;
        grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
        background-color: var(--gray-color-90);
    }
`;

export const Tabs = () => {
    return (
        <STabsWrapper>
            <SNavigation
                columns={SECTIONS_ID.length}
                data={SECTIONS_TRANSLATION}
                ids={SECTIONS_ID}
            />
        </STabsWrapper>
    );
};
