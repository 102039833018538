import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { generateKebabCaseId } from 'utilities/index';

export const useTranslatedTitle = (title) => {
    const { formatMessage } = useIntl();

    if (typeof title !== 'string' || !title.trim()) {
        // Invalid title: expected a non-empty string
        return { translatedTitle: '', kebabCaseId: null };
    }

    let translatedTitle;
    try {
        translatedTitle = formatMessage({ id: title });

        if (typeof translatedTitle !== 'string') {
            throw new TypeError(`Translation for "${title}" is not a string.`);
        }
    } catch {
        // Failed to translate title
        return { translatedTitle: '', kebabCaseId: null };
    }

    const kebabCaseId = generateKebabCaseId(title);

    const handleCopyUrl = () => {
        const currentUrl = `${window.location.origin}${window.location.pathname}#${kebabCaseId}`;
        navigator.clipboard.writeText(currentUrl).then(
            () => {
                toast.success(
                    formatMessage({
                        id: 'general.copied',
                    }),
                    {
                        position: 'bottom-center',
                        duration: 1500,
                    },
                );
            },
            () => {
                toast.error(
                    formatMessage({
                        id: 'general.copyFailed',
                    }),
                    {
                        position: 'bottom-center',
                        duration: 1500,
                    },
                );
            },
        );
    };

    return { translatedTitle, kebabCaseId, handleCopyUrl };
};
