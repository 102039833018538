import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { subscribeNewsletter } from 'api/newsletter';
import { Button } from 'components/button-new';
import { Checkbox } from 'components/checkbox-new';
import { Input } from 'components/input-new';
import { Overlay } from 'components/overlay';
import { Paragraph } from 'components/paragraph';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

import { FORM_FIELDS, validationSchema } from './validation';

const SBanner = styled.div`
    margin-top: 2rem;
    width: 100%;
    padding: 2rem;
    background-color: var(--gray-color-90);
`;

const SContent = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
`;

const SButtonWrapper = styled.div`
    display: flex;
    gap: 1rem;
    grid-column-start: 2;
    align-items: center;

    ${CONSTANTS.MEDIA.max440`
        flex-direction: column;

        & button {
            width: 100%;
        }
    `}
`;

const STitle = styled.h2`
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 1rem;
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    ${CONSTANTS.MEDIA.max768`
        display: flex;
        flex-direction: column;
    `}
`;

const SParagraph = styled(Paragraph)`
    margin-top: 18px;
`;

export const BlogSubscribeBanner = () => {
    const { formatMessage } = useIntl();
    const { control, formState, handleSubmit, reset } = useForm({
        resolver: validationSchema,
        defaultValues: {
            [FORM_FIELDS.PRIVACY_POLICY]: false,
            [FORM_FIELDS.EMAIL]: '',
        },
    });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async ({ email }) => {
        setIsLoading(true);
        try {
            await subscribeNewsletter({
                email,
            });

            reset();
            toast.success(formatMessage({ id: 'subscribeBanner.success' }));
        } catch {
            toast.error(formatMessage({ id: 'subscribeBanner.error' }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <SBanner>
            <Overlay show={isLoading} />
            <SContent>
                <STitle>
                    <FormattedMessage id="subscribeBanner.title" />
                </STitle>
                <SWrapper>
                    <SParagraph>
                        <FormattedMessage id="subscribeBanner.content" />
                    </SParagraph>
                    <Input
                        control={control}
                        name={FORM_FIELDS.EMAIL}
                        label={formatMessage({
                            id: 'subscribeBanner.emailAddress',
                        })}
                        error={formState.errors[FORM_FIELDS.EMAIL]}
                        containerClassName="inputContainer"
                        isRequired
                    />
                    <SButtonWrapper>
                        <Button onClick={handleSubmit(onSubmit)}>
                            <FormattedMessage id="subscribeBanner.subscribe" />
                        </Button>
                        <Checkbox
                            control={control}
                            name={FORM_FIELDS.PRIVACY_POLICY}
                            label={formatMessage(
                                {
                                    id: 'subscribeBanner.privacyPolicy',
                                },
                                {
                                    link: (chunks) => (
                                        <PrefixLangLink
                                            to={PATHS.PRIVACY_POLICY}
                                        >
                                            {chunks}
                                        </PrefixLangLink>
                                    ),
                                },
                            )}
                            error={formState.errors[FORM_FIELDS.PRIVACY_POLICY]}
                            isRequired
                        />
                    </SButtonWrapper>
                </SWrapper>
            </SContent>
        </SBanner>
    );
};
