import React from 'react';
import { NewsView } from 'views/news';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/blog.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BlogPage = ({ location, data }) => {
    const blogPosts = data.allContentfulBlogPost.edges;
    const filteredBlogPosts = blogPosts.filter(
        (post) =>
            !post.node.metadata.tags.some(
                (tag) => tag.name === 'press-release',
            ),
    );

    return (
        <Layout location={location} messages={messages}>
            <NewsView blogPosts={filteredBlogPosts} />
        </Layout>
    );
};

BlogPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export default BlogPage;

export const Head = () => <SEO tags={TAGS.BLOG} />;

export const pageQuery = graphql`
    query EnBlogPostQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: { elemMatch: { name: { ne: "nft-reality" } } }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    id
                    title
                    genre
                    genreExpertise
                    genreTechnology
                    genreTechnologies
                    shortDescription {
                        internal {
                            content
                        }
                    }
                    createdAt
                    date
                    updatedAt
                    image {
                        gatsbyImageData(width: 772, height: 541, quality: 100)
                    }
                    thumbnail {
                        gatsbyImageData(width: 600, height: 420, quality: 100)
                    }
                    slug
                    altThumbnailDescription
                    metadata {
                        tags {
                            name
                        }
                    }
                    multipleAuthors {
                        name
                        jobTitle
                        picture {
                            gatsbyImageData(width: 200, quality: 100)
                        }
                    }
                }
            }
        }
    }
`;
