import { PATHS } from 'constants/paths';
import BlockchainSvg from 'svgs/blog/blockchain.svg';
import FintechSvg from 'svgs/blog/fintech.svg';
import HrSvg from 'svgs/blog/hr.svg';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import ProptechSvg from 'svgs/blog/proptech.svg';
import SoftwareBusinessSvg from 'svgs/blog/software-business.svg';

import { HUB_TAGS, HUB_URLS } from '../../single-blog-genre/constants';

export const SECTIONS_TRANSLATION = [
    'list.fintech.title',
    'list.proptech.title',
    'list.blockchain.title',
    'list.javascript.title',
    'list.software-business.title',
    'list.hr.title',
];

export const SECTIONS_ID = [
    'fintech',
    'proptech',
    'blockchain',
    'javascript',
    'softwareBusiness',
    'hr',
];

export const SECTIONS = [
    {
        title: 'list.fintech.title',
        description: 'list.fintech.description',
        tags: HUB_TAGS.FINTECH,
        buttonLabel: 'list.fintech.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.FINTECH}`,
        svg: FintechSvg,
        id: SECTIONS_ID[0],
        hub: HUB_URLS.FINTECH,
    },
    {
        title: 'list.proptech.title',
        description: 'list.proptech.description',
        tags: HUB_TAGS.PROPTECH,
        buttonLabel: 'list.proptech.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.PROPTECH}`,
        svg: ProptechSvg,
        id: SECTIONS_ID[1],
        hub: HUB_URLS.PROPTECH,
    },
    {
        title: 'list.blockchain.title',
        description: 'list.blockchain.description',
        tags: HUB_TAGS.BLOCKCHAIN,
        buttonLabel: 'list.blockchain.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.BLOCKCHAIN}`,
        svg: BlockchainSvg,
        id: SECTIONS_ID[2],
        hub: HUB_URLS.BLOCKCHAIN,
    },
    {
        title: 'list.javascript.title',
        description: 'list.javascript.description',
        tags: HUB_TAGS.JAVASCRIPT,
        buttonLabel: 'list.javascript.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        svg: JavascriptSvg,
        id: SECTIONS_ID[3],
        hub: HUB_URLS.JAVASCRIPT,
    },
    {
        title: 'list.software-business.title',
        description: 'list.software-business.description',
        tags: HUB_TAGS.SOFTWARE_BUSINESS,
        buttonLabel: 'list.software-business.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.BUSINESS}`,
        svg: SoftwareBusinessSvg,
        id: SECTIONS_ID[4],
        hub: HUB_URLS.SOFTWARE_BUSINESS,
    },
    {
        title: 'list.hr.title',
        description: 'list.hr.description',
        tags: HUB_TAGS.HR,
        buttonLabel: 'list.hr.button-label',
        moreUrl: `${PATHS.BLOG}/${PATHS.HR}`,
        svg: HrSvg,
        id: SECTIONS_ID[5],
        hub: HUB_URLS.HR,
    },
];
